@import '../variables.scss';
$primaryColor: #000B49;

.header{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
}

header{
    color: white;
    background: $primaryColor;
    padding: 20px 0;
    position: relative;
    


   
    .container{
       

        .main-header{
            margin: 10px auto;
            .logo{
                width: 20%;
                height: 80px;
                
            }

            .group-search{
                width: 45%;
                display: flex;
                margin-right: 30px;
                position: relative;
                input{
                    background-color: #f3f3f3;
                    // flex-grow: 1;
                    padding: 10px 15px;
                    border: 1px solid rgba($color: #000000, $alpha: 0.3);
                    border-radius: 5px;
                    width: 100%;

                }
                .search-product{
                    z-index: 100;
                    // left: 15px;
                    width: 99.7%;
                    height: auto;
                    background-color: white;
                    position: absolute;
                    box-shadow: 0 10px 10px rgba($color: #000000, $alpha: 0.3);
                    transform: translate(1px,40px);
                    // border: 1px solid rgba($color: #000000, $alpha: 0.3);
                    .item-search-product{
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        position: relative;
                        padding-bottom: 10px;
                        transition: all 0.4s;
                        &:hover{
                        // background-color: rgba($color: $primary-color, $alpha: 0.5);
                        background-color: #e7ebfd;
                    }
         
                       .flex{
                        display: flex;
                        margin-top: 10px;
                        margin-left: 20px;
                       }
                        .image{
                            width: 60px;
                            height: 60px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .content-item{
                            padding-left: 10px;
                            display: flex;
                            align-items: center;
                            .a-center{
                            .name{
                                display: block;
                                font-size: 12px;
                                font-weight: bold;
                            }
                            .author{
                                display: block;
                                font-size: 11px;
                                padding-top: 5px;
                                // padding-bottom: 10px;
                            }
                            .priceDeal{
                                font-size: 13px;
                                color: $red;
                            }
                            .priceOld{
                                font-size: 13px;
                                color: rgba($color: #000000, $alpha: 0.7);
                                padding-left: 15px;
                                text-decoration: line-through;
                            }
                        }

                        }
                    }
                }
            }

            .cart{
                position: relative;
                margin-right: 30px;
                span{
                    position: absolute;
                    width: auto;
                    padding: 5px 7px;
                    height: 20px;
                    border: 1px solid black;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    color: $primaryColor;
                    top: -10px;
                    right: -15px;
                }
            }
            .top-header{
                position: relative;
                // display: flex;
                // padding-bottom: 10px;
                .profile{
                    transition: all .5s;
                    display: flex;                
                    // width: auto;
                    padding-bottom: 10px;
                    
                    .login-complete{
                        // display: flex;
                        // justify-content: flex-end;
                        // align-items: center;
                        transition: all .3s;
                        // float: right;
                        padding: 10px 0;
    
                        &:hover {
                            transition: all .3s;
                            .dropdown-profile{
                                z-index: 9;
                                display: block;
                                // position: relative;
                                &::before,
                                &::after{
                                    content: '';
                                    position: absolute;
                                    right: 22.5px;
                                    top: -6px;
                                    width: 12px;
                                    height: 12px;
                                    transform: rotate(45deg);
                                    z-index: -1;
                                    background: white;
                                    border-top: 1px solid white;
                                    border-left: 1px solid white ;
                                    border-bottom: 1px solid transparent;
                                    border-right: 1px solid transparent ;
                                }
        
                                &::after{
                                    z-index: 2;
                                    right: 22.5px;
                                    top: -6px;
                                }
                            }
                        }
                        
                        
                    }
                    .dropdown-profile{
                        // padding: 10px;
                        z-index: 99;
                        width: 200px;
                        position: absolute;
                        right: 0;
                        margin-top: 10px;
                        display: none;
                        background: white;
                       
                        ul{
                            
                            li{
                                padding: 10px 15px;
                                color: #000;
                                // background: white;
                                border: 1px solid rgba(#333, 0.1);
                                transition: all .3s;
                                &:hover{
                                    // background:rgb(200, 199, 216);
                                    color: rgb(44,172,218);
                                } 
                            }
                        }
                    }
    
                }
                
                .avatar{
                    
                    span{
                        color: white;
                        margin-left: 10px;
                    }
                    img{
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        overflow: hidden;
    
                    }
                    margin-right: 10px;
                }
                .success-login{
                    // text-align: end;
                    cursor: pointer;
                    span{
                        transition: all .2s;
                        color: white;
                        // &:hover{
                        //     text-decoration: underline;
    
                        // }
                    }
                }
    
            
                .login{
                    text-align: end;
                    cursor: pointer;
                    span{
                        transition: all .2s;
                        color: white;
                        &:hover{
                            text-decoration: underline;
    
                        }
                    }
                    
                }
            }   
        }

        .header-content{
            margin-top: 30px;
            .list-categories{
                .item{
                    &:hover{
                        span,img{
                            color: #607EAA;
                        }
                    }
                    span{
                        color: white;
                        margin-left: 5px;
                       
                    }

                    img{
                        // margin-top: 5px;
                       // position: absolute;
                    //    transform: translate(-20px,0px);
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}