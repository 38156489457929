
@import "../variables.scss";
.empty-cart{
    background: white;
    flex-direction: column;
    height: 70vh;
    img{
        width: 100px;
        height: 100px;
    }
    p{
        margin: 10px auto;
    }
    button{
        width: 500px;
        color: #288ad6ed;
        border: 1px solid #288ad6;
        padding: 15px 0;
        border-radius: 7px;
        font-size:18px;
        margin-top: 10px;
        transition: all 0.3s;
        &:hover{
            background-color: #288ad6;
            color: white;
        }
    }
}