@import '../variables.scss';


.register{
    // position: absolute;
    // background: white;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 5;
    .header-signUp{
        height: 150px;
        width: 100%;
        background-color: $primary-color;
        top: 0;
        position: absolute;
        z-index: 10;
        .container{
            height: 100%;
            display: flex;
            align-items: center;
            .logo{
                img{

                }
            }
            span{
                color: white;
                padding-left: 20px;
                font-size: 30px;
                position: relative;
                transform: translateY(-7px);
                font-weight: bold;
            }
        }
    }

    .main{
        background-color: #f3f3f3;
        padding-top: 50px;
        .container{
            // height: 100vh;
            padding-bottom: 150px;
            display: flex;
            justify-content: center;
            // align-items: center;
            .form{
                // margin-top: 50px;
                width: 900px;
                height: auto;
                background-color: white;
                border-radius: 30px;
                box-shadow: 0 15px 16.83px 0.17px rgb(0 0 0 / 5%);
                padding: 50px 80px;
                display: flex;
                .err-msg{
                    display: block;
                    // padding-left: 5px ;
                    padding-top: 5px;
                    color: $red;
                    font-size: 12px;
                }
                .main-form{
                    width: 70%;
                    .sign-up{
                        font-size: 40px;
                        color: $primary-color;
                        font-weight: bold;
                    }
                    .input-group{
                        padding-top: 20px;
                        
                        input{
                            width: 70%;
                            padding: 8px 30px;
                            border-bottom: 1px solid #000;
                            margin-top: 20px;
                        }
                        .ic{
                            padding-left: 10px;
                            font-size: 12px;
                            position: absolute;
                            padding-top: 10px;
                            // transform: translate(0px,10px);
                            // left: 5px;
                        }
                    }
                    .policy{
                        padding-top: 30px;
                        span{
                            padding-left: 10px;
                            u{
                                color: $primary-color;
                                cursor: pointer;
                            }
                        }
                        input{
                            width: 15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }

                    .btn-sign-up{
                        margin-top: 50px;
                        padding: 15px 50px;
                        background-color: #6dabe4;
                        color: white;
                        border-radius: 10px;
                        transition: all 0.4s;
                        &:hover{
                            background-color: #3271ac;
                        }
                    }
                }
                .img{
                    width: 30%;
                    img{
                        position: absolute;
                        transform: translate(-50px,50px);
                    }
                }
            }
        }
    }

}

