


.tablet{
    .list-product{
       // padding-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item-product{
            width: 19%;
            margin-top: 20px;
            background: white;
            border: 1px solid rgba(#333,0.1);
            border-radius: 4px;
        }
    }
    
}
