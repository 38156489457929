@import '../../../../sass/variables.scss';
.admin-product-list{
    .image-product{
      
      
        img{
            
            width: 100px;
            height: 70px;
           
        }
    }
    .name-product{
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
    }
}




.admin-product .add-product{
    display: flex;
}
.admin-product .add-product span{
    font-size: 35px;
}
.admin-product .add-product span:hover{
    color: #1890ff;
    transition: .3s;
}
.admin-product-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 6.5%;
    .input-search{
        margin-left: 20px;
        position: relative;
        .ic{
            position: absolute;
            font-size: 15px;
            transform: translateY(50%);
            bottom: 50%;
            left: 15px;
        }
        input{
            padding: 10px 40px;
            border-radius: 8px;
            background-color: white;
            width: 500px;
        }
    }
    button{
        display: flex;
        align-items: center;
        font-size: 20px;
        background: #379237;
        padding: 10px 10px;
        color: white;
        font-weight: 400;
        margin: 20px 0;
        border-radius: 6px;
        margin-right: 40px;
    }

}
.admin-product span{
    display: flex;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #6b6b6b;
}
.admin-product-list{
    background: white;
    margin: 0 .5rem 0 1rem;
    color: #6b6b6b;
    padding: 1rem;
    width: 90%;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.admin-product-list table{
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-collapse: collapse;
}

.admin-product-list table th{
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 600;
    background: #eff2f7;
    padding: .5rem 0;
   // width: 10%;
}
.admin-product-list table td{
    padding: .5rem 0;
    font-size: 16px;
}
.admin-product-list table td img{
    width: 100px;
}


.admin-product-list tr .delete-product, .admin-product-list tr .update-product, .review-product{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    text-align: center;
}
.admin-product-list tr .delete-product span, .admin-product-list tr .update-product span{
    border-radius: 50%;
}
.admin-product-list tr .delete-product:hover span{
    background: rgb(223, 28, 28);
    transition: .3s;
}
.admin-product-list tr .update-product:hover span{
    background: #1890ff;
    transition: .3s;
}
.admin-product-list tr .delete-product:hover span, .admin-product-list tr .update-product:hover span{
    color: white;
}

.admin-product-list .pagination{
    padding: 1rem 0;
    display: flex;
}
.admin-product-list .pagination span{
    display: block;
    padding: 0;
    font-size: 14px;
    font-weight: 0;
}


/* ----- create product */
/* .admin-create{

} */
.admin-create > span{
    display: flex;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #6b6b6b;
}

.input-group{
    // position: relative;
    select{
        padding: 7px 15px;
    }
    span{
        display: block;
    }
    .title{
        // display: inline-block;
        margin: 10px auto;
    }
    input{
        display: block;
   
    }

    .err-msg{
        // height: 10px;
        display: block;
        // padding-left: 0px ;
        // margin-top: 5px;
        color: orangered;
        font-size: 13px;
        // position: absolute;
        font-style: italic;
        transform: translateY(-5px);
    }
}


.admin-create-product{
    display: flex;
    flex-direction: column;
    background: white;
    margin: 0rem .5rem 1rem 1rem;
    padding: 1rem;
    width: 90%;

    span{
        margin-bottom: 10px;

    }
}
.admin-create-product input, select, textarea{
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;

    width: 80%;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.select-type{
    margin-bottom: 1rem;
}
.admin-create-product button{
    background: white;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    border: 1px solid #333;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    width: 80%;
    transition: all .3s;
 
}
.admin-create-product button:hover{
    background: #1890ff;
    color: white;
    border: 1px solid transparent;
    transition: .3s;
}
.admin-create-product .filter-menu-firm{
    margin-top: 0;
    margin-bottom: 1rem;
}

.image-upload > input
{
    display: none;
}
.upload-icon{
	width: 100px;
	height: 97px;
	border: 2px solid #ced4da;
	border-style: dotted;
	border-radius: 18px;
    img{
        width: 100%;
    }
}
.upload-icon img{
	width: 60px;
	height: 60px;
	margin:19px;
	cursor: pointer;
}

.img-group{
    form{
        margin-right: 20px;
    }
}

.btn-see-more{
    text-align: center;
    button{
        margin-top: 50px;
        // padding: 10px 30px;
        padding: 15px 50px;
        background-color: $btn-color;
        color: white;
        border-radius: 5px;
        cursor: pointer;      
        font-size: 18px; 
        transition: all 0.4s;
       
        &:hover{
            background-color: #3271ac;
        }
    }
}
