.font {
    font-family: "Lexend", sans-serif;
    display: grid;
  }
  .thetopHoidap {
    padding-top: 10px;
    width: 1200px;
    margin: 0 auto;
  }
  .titlehoidap {
    font-size: 18px;
    padding-left: 10px;
  }
  .thetopHoidap ul li {
    display: inline;
    cursor: pointer;
  }
  .thetopHoidap ul li.dangnhap {
    float: right;
  }
  .dangnhap {
    height: 20px;
    padding: 10px 0;
    position: relative;
    font-size: 15px;
  }
  .dangnhap i {
    color: #288ad6;
  }
  .hoiidapsp {
    color: #288ad6;
    float: left;
    overflow: hidden;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .mainHoidap {
    width: 1200px;
    margin: auto;
    display: flex;
    column-gap: 50px;
  }
  .col_topic {
    width: 320px;
  }
  
  .menuhoidap {
    width: 320px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(255, 172, 10, 0.6);
    padding-left: 5px;
    padding-right: 5px;
    text-align: justify;
  }
  .csschoi {
    border-top: #288ad6;
    border-top: 0.5px solid #288ad6;
  }
  .listmenuxx ul li {
    display: none;
  }
  [id^="btn"]:checked + ul li {
    display: block;
  }
  .listmenuxx li label {
    cursor: pointer;
    font-size: 18px;
    padding-left: 10px;
  }
  .listmenuxx li ul li {
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
  }
  .listmenuxx li label:hover {
    color: #288ad6;
  }
  .listmenuxx li ul li:hover {
    color: #288ad6;
  }
  .listmenuxx li label span {
    font-size: 20px;
    float: right;
    margin-top: 10px;
    padding: 0 10px;
    transition: 0.5s;
  }
  
  
  .csschoi input {
    display: none;
  }
  
  .searchform {
    display: block;
    position: relative;
    border-radius: 3px;
    margin-bottom: 10px;
    background: #eee;
    padding: 10px;
    min-width: 830px;
    display: flex;
  }
  .searchform input {
    width: 55%;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0;
    height: 38px;
    text-indent: 30px;
    border-radius: 3px;
    border: 1px solid #eee;
  }
  .searchform div i {
    background-position: 0 -35px;
    height: 16px;
    top: 23px;
    position: absolute;
    left: 24px;
  }
  .searchform button {
    color: #288ad6;
    border: 1px solid #288ad6;
    border-radius: 4px;
    text-transform: unset;
    background: #fff;
    padding: 10px 30px;
    position: unset;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
  .searchform div {
    font-size: 13px;
    padding: 12px 10px 10px 20px;
  }
  .titletbaivietmoinhat {
    float: left;
    width: 50%;
    padding: 15px 0;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
  }
  .item_hoidap {
    width: 830px;
    display: flex;
    border-top: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .item_hoidap img {
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
  }
  .title_noidung {
    line-height: 26px;
    color: #333;
    font-size: 20px;
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 5px;
    padding-left: 20px;
  }
  .title_noidung:hover {
    color: #288ad6;
    font-size: 20.1px;
  }
  .title_noidung_nho {
    color: #747474;
    font-size: 14px;
    cursor: pointer;
    padding-left: 20px;
  }
  