
@import '../variables.scss';
*{
    scroll-margin-top: 183px;
}

@keyframes top-deal{
    0% {
        width: 50%;
    }
    25% {
        width: 100%;
    }
}

.home{
    background-color: $second-color;
    
    .big-banner{
        height: 450px;
        .banner{
            img{
                width: 100%;
                position: relative;
                // height: 50px;
            }

            .sliders{
                position: absolute; 
                transform: translate(50%,-80px);
                right: 50%;
                .slick-slider{
                    width: 1000px;
                    img{
                        border-radius: 10px;
                        width: 98%;
                        margin-left: 10px;
                    }
                

                .slick-arrow{
                    background: white !important;

                    i{
                        color: #666 !important;
                        transition: all .4s;
                    }
                    &:hover{
                        i{
                            color: #333 !important;
                        }
                    }
                    // &::before{
                        
                    //     color: white;
                    //     font-size: 50px;
                    //     position: absolute;
                    //     transform: translate(50%, 50%);
                    //     right: 50%;
                    //     bottom: 50%;
                        
                    // }

                    &.slick-next{
                        position: absolute;
                        box-shadow: -6px 0 4px rgba(#333,0.3);
                        top: 45%;
                        transition: all 0.4s;
                        // &:hover{
                        //    background-color: #e0e0e0 !important;
                        // }
                        &::before{
                           font-size: 0 !important;
                           
                        }
                      
                    }
                    &.slick-prev{
                        box-shadow: 6px 0 4px rgba(#333,0.3);
                        transition: all 0.4s;
                        top: 45%;
                        left: -12px;
                        // &:hover{
                        //     background-color: #e0e0e0 !important;
                        // }
                        &::before{
                           font-size: 0 !important;
                        }
                    }
                    
                }

            }

            }
        }
    }

    .container{

        .option-promote{
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            .item{
                cursor: pointer;
                width: 22%;
                height: 80px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                padding: 0 20px;
                span{
                    font-size: 23px;
                    padding-left: 15px;
                    text-align: center;
                }
            }
        }

        .banner-home{
            .banner{
                margin: 100px 0px 20px 0px;
                img{
                    width: 100%;
                }
            }
        }
            
        .flash-sales{
            height: 980px;
            background-color: white;
            margin-top: 60px;
            border-radius: 20px;
            padding: 20px 50px;
            .title-flash{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title{
                    span{
                        font-size: 30px;
                        color: #CE1212;
                        .icon{
                            font-weight: bold;
                        }
                    }
                    img{
                        width: 30px;
                    }
                }
                .time-sale{
                    width: 60%;
                    ul{
                        display: flex;
                        justify-content: space-between;
                        
                        li{
                            text-align: center;
                            .time-line{
                                font-size: 20px;
                                padding-top: 5px;
                                font-weight: bold;
                                position: relative;
                                &::before{
                                    content: '';
                                    width: 30%;
                                    position: absolute;
                                    border: 1px solid black;
                                    bottom: -5px;
                                    right: 50%;
                                    transform: translateX(50%);
                                }
                            }
                        }
                    }
                }
            }
            .end-sale{
                display: flex;
                align-items: center;
                padding-top: 20px;
                p{
                    font-size: 20px;
                    font-weight: bold;
                }
                .count-down{
                    padding-left: 10px;
                    span{
                        background-color: #F0A500;
                        padding: 5px;
                        border-radius: 5px;
                        margin: 0 5px;
                    }
                }
            }

            .list-product{
                padding-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item-product{
                    margin-top: 20px;
                    .image{
                        height: 150px;
                    }
                    background-color: white;
                    border: 1px solid #e4e4e4;
                    box-shadow: 0 0 10px 0 rgba($color: #e4e4e4, $alpha: 0.7);
                }
            }

        }

        .menu-options{
            padding-top: 50px;
            // padding-bottom: 40px;
            display: flex;
            justify-content: space-between;
            .option{
                width: 32%;
                border-radius: 5px;
                overflow: hidden;
                cursor: pointer;
                min-height: 1px;
                position: relative;
                clear: both;
                &:hover{
                    &::before{
                        content: '';
                        z-index: 5;
                        width: 92%;
                        height: 85%;
                        border: 1px solid white;
                        position: absolute;
                        transform: translate(50%,50%);
                        right:50%;  
                        bottom: 50%;
                        // transition: all 0.4s;
    
                    }
                    img{
                        transform: scale(1.1);
                    }
                }
               
                img{
                    width: 100%;
                    height: 100%;
                    transform: scale(1);
                    transition: all 0.4s;
            
                }
                .title-watches{
                    
                    width: 150px;
                    color: white;
                    position: absolute;
                    transform: translate(-380px,90px);
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }

        .list-watches{
            height: auto;
            display: flex;
            // margin-bottom: 100px;
            padding-top: 70px;
            padding-bottom: 60px;
            // flex-wrap: wrap;
            .banner{
                width: 50%;
                height: 100%;
                img{
                    width: 100%;
                }
            }
            .list-product-watches{
                width: 65%;
                height: 796px;
                padding-left: 30px;
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-between;
                // margin-top: 70px;
                .list-product{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .item-product{
                        background-color: white;
                        margin-bottom: 28px;
                        height: 385px;
                    }
                }
            }

          

        }

        .banner-galaxy{
            width: 100%;
            .banner{
                width: 100%;
                height: 350px;
                padding-top:0px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .list-phone{
            height: auto;
            .title{
                display: flex;
                align-items: center;
                .img{
                    width: 50px;
                    padding-right: 15px;
                    img{
                        width: 100%;
                    }
                }
                h3{
                    font-size: 28px;
                    color: #F49D1A;
                }
            }
            .list-product{
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item-product{
                    width: 250px;
                    height: 450px;
                    // width: 50%;
                    // height: 400px;
                    margin-bottom: 20px;
                    background: white;
                    border-radius: 4px;
                    padding: 30px;
                    .name-product{
                        width: 200px;
                    }
                    .image{
                        img{
                            width: 100%;
                            height: 150px;
                        }
                    }
                }
            }
        }

        .shopping-trends{
            margin: 30px auto;
            .banner{
                img{
                    width: 49%;
                }
            }
            h3{
                color: red;
                margin: 20px auto;
                font-size: 25px;
            }

            .list-product{
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item-product{
                    width: 19%;
                    margin-bottom: 20px;
                    background: white;
                    border-radius: 4px;
                   
                }
            }
            
        }

        .great-deals{
            h3{
              
                margin-bottom: 10px;
                font-size: 25px;
            }

            .banner_deals{
               
                img{
                    width: 32%;
                    border-radius: 10px;

                }
            }
        }

        .recommend-today{
            margin: 30px auto;
            .title{
                // display: flex;
                // align-items: center;
                // justify-content: center;
                text-align: center;
               
                // border: 1px solid #5856d6;
                padding: 10px;
                width: 200px;
                // border-radius: 50px;
                // background-color: white;
                position: relative;
                color: #00B4DB;
                transition: all 0.3s;
                &.active-top-deal{
                    &::after{
                        animation-name: top-deal;
                        animation-duration: 2s;
                        width: 100%;
                    }
                    &::before{
                        animation-name: top-deal;
                        animation-duration: 2s;
                        width: 100%;
                    }
                    

                }
                // overflow: hidden;
                &::before{
                    position: absolute;
                    content: '';
                    width: 50%;
                    height: 3px;
                    // border-color: #5856d6;
                    background: #00B4DB;  
                    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  
                    background: linear-gradient(to right, #0083B0, #00B4DB); 

                    top: 0;
                    left: 0;
                    
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 50%;
                    height: 3px;
                    // border-color: #5856d6;
                    background: #00B4DB;  
                    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  
                    background: linear-gradient(to right, #0083B0, #00B4DB); 

                    bottom:  0;
                    right: 0;
                    
                }
               

                h3{
                    padding-right: 10px;
                    font-weight: bold;
                    font-size: 28px;
                    
                }
            }
            
            .list-product{
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item-product{
                    width: 250px;
                    height: 450px;
                    // width: 50%;
                    // height: 400px;
                    margin-bottom: 20px;
                    background: white;
                    border-radius: 4px;
                    padding: 30px;
                    .image{
                        img{
                            width: 100%;
                            height: 180px;
                        }
                    }
                }
            }
        }

        .tech24h{
            margin: 20px auto;
            padding-bottom: 20px;
            h3{
                font-size: 28px;
                //margin-bottom: 10px;

            }
            .list-news{
                
                .news{
                    margin: 20px 0;
                    
                    img{
                        width: 100%;
                        height: 80%;
                        
                    }
                    width: 30%;
                    p{
                        //width: 75%;
                       // margin: auto 5px;
                       display: -webkit-box;
                       -webkit-line-clamp: 2;
                       -webkit-box-orient: vertical;
                       overflow: hidden;
                       font-size: 17px;
                       transition: all .3s;
                       &:hover{
                        color: #CE1212;
                       }
                    }
                }
            }
        }
    }
}


.scroll-to-top{
    height: 45px;
    width: 45px;
    border: 1px solid $primary-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 100;
    transition: all 0.2s;
    cursor: pointer;
    .ic{
        color: $primary-color;
    }
    &:hover{
        background-color: $primary-color;
        .ic{
            color: white;
        }
    }
}