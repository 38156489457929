@import '../variables.scss';
.main-product-detail{
    margin: auto 100px;
    .product-detail{
        .product-category{
            margin: 20px auto;
            i{
                margin: auto 5px;
            }
        }
        .product-title{
            .product-title-star{
                margin: auto 20px;
                .star-outer{
                    padding-bottom: 10px;
                }
                .star-inner{
                    padding-bottom: 10px;
                }
            }
        }
        .product-introduce{
            margin: 30px auto;
           .product-detail-image{
             width: 500px;
             height: 500px;
                img{
                    &:focus{
                        outline: none;
                    }
                    width: 100%;
                }

                
            .slick-arrow{
                background: white !important;
                i{
                    color: #666 !important;
                    transition: all .4s;
                }
                &:hover{
                    i{
                        color: #333 !important;
                    }
                }
                &.slick-next{
                    transition: all 0.4s;
                    box-shadow: 6px 0 4px rgba(#333,0.3);
                    // &:hover{
                    //   // background-color: #e0e0e0 !important;
                    //    i{
                    //     color: #333 !important;
                    //     }
                    // }
                    &::before{
                    font-size: 0 !important;
                    }
                }
                &.slick-prev{
                    transition: all 0.4s;
                    box-shadow: -6px 0 4px rgba(#333,0.3);
                    // &:hover{
                    //    //background-color: #e0e0e0 !important;
                    //     i{
                    //         color: #333 !important;
                    //     }
                    // }
                    &::before{
                    font-size: 0 !important;
                    }
                }
            }

                .product-introduce-expand{
                    margin-top: 50px;
                    justify-content: space-between;
                    .product-introduce-expand-item{
                        flex-direction: column;
                        text-align: center;
                        .expand-item-logo{
                           // align-items: center;
                            border: 2px solid #999;
                            border-radius: 6px;
                            cursor: pointer;
                            font-size: 1.8rem;
                            padding: 10px;
                            width: 54px;
                            height: 54px;
                        }
                        .product-introduce-expand-item-text{
                          //  width: 70px;
                            margin-top: 10px;
                            
                        }
                    }
                }
            } 

           .product-detail-content{

                width: 48%;
                .product-option{
                    button{
                        padding: 8px;
                        margin-right: 10px;
                        border: 1px solid rgba(#333,0.7);
                        border-radius: 4px;
                        width: 10%;
                    }
                }

                .product-price-location{
                    .location{
                        margin: 10px auto;
                    }
                    .product-price{
                        span{
                            margin-right: 5px;
                        }
                        .pricenew{
                            color: $primary-color;
                            font-weight: bold;
                        }
                        .priceold{
                            text-decoration: line-through;
                        }
                    }
                }

                
                .product-introduce-promotion{
                    margin: 15px auto;

                }
                .product-introduce-promotion-title {
                    background-color: #d9d9d9;
                    font-size: 14px;
                    padding: 0 5px;
                   
                }

                .product-introduce-promotion-list{
                    margin: 10px auto;

                    .product-introduce-promotion-item{
                        margin-top: 10px;
                        display: flex;
                        .promotion-circle {
                            align-items: center;
                            background-color: blue;
                            border-radius: 50%;
                            display: flex;
                            height: 16px;
                            justify-content: center;
                            margin: 0 5px;
                            width: 16px;
                        }
                    }
                    
                }

                .border {
                    border: 2px solid #999;
                    border-radius: 6px;
                    
                }

                .product-introduce-payment{
                    .product-introduce-payment-title{
                        h3{
                            font-size: 16px;
                            font-weight: 500;
                        }
                        
                    }
                    .product-introduce-payment-main{
                        margin: 10px auto;
                        .product-introduce-payment{
                            padding: 5px;
                            width: 45%;
                        }
                    }
                }

                .btn-buy-now{
                 
                    border-color: transparent;
                    border-radius: 6px;
                    font-size: 14px;
                    font-weight: 500;
                    height: 44px;
                    margin-top: 16px;
                    width: 100%;
                    color: white;
                    background: $primary-color;
                    
                }
            
           }
        }
        .product-information{
            .information-product{
                // width: 48%;
                p{
                    margin: 10px auto;
                }
            }
            .information-technical{
                width: 48%;
                .technical-detail{
                    
                    width: 100%;
                    margin: 10px auto;
                    tr:nth-child(odd){
                        background: #f5f5f5;
                    }
                    th,td{
                        text-align: left;
                        padding: 10px;
                    }
                    th{
                        font-weight: normal;
                    }
                }
            }
        }
        .product-feedback{
            margin: 20px auto;
            .product-review{
                // width: 48%;
                border: 1px solid #999;
                border-radius: 10px;
                padding: 25px;
                
            }
            .product-ask{
                width: 48%;
                .product-ask-input{
                    display: flex;
                    margin: 15px auto;
                    input{
                        background: white;
                        padding: 10px;
                        border: 1px solid #e4e4e4;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        flex-grow: 1;
                        
                    }
                    .btn-send{
                        background: $primary-color;
                        color: white;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        padding: 0 10px;
                        font-size: 14px;
                        i{
                            margin-right: 5px;
                        }
                    }

                }
                
            }
            .product-review-statistics{
                margin: 10px auto;
                .product-review-statistic-star{
                    // width: 50%;
                    //margin-top: 20px;

                    .star-average{
                        margin-left: 20px;
                        .star-number{
                            margin-right: 15px;

                        }
                        
                    }
                    .star-sum{
                        margin-right: 55px;
                    }

                    .product-review-list{
                        margin-top: 20px;
                        .review-avatar{
                            width: 40px;
                            height: 40px;
                            img{
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                overflow: hidden;

                            }
                        }

                        .review-content{
                            margin-bottom: 10px;

                            .content{
                                margin-top: 10px;
                            }
                            .time-review{
                                margin-top: 10px;
                                font-size: 14px;
                                color: #999;
                            }
                        }
                       
                    }
                }
                .product-review-statistic-image{
                    width: 40%;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    img{
                        width: 50%;
                        margin-bottom: 5px;
                    }
                }
                .product_review_statistic_star_list{
                    margin-top: 20px;
                }
            }
            .btn-review{
                button{
                    background: $primary-color;
                    color: white;
                    width: 30%;
                    margin: 15px 40px;
                    padding: 10px;
                    border-radius: 6px;
                }
                i{
                    margin-right: 5px;
                }
            }
        }
    }
}