

.layout-admin{
    display: flex;
    height: 100vh;
}
.layout__content{
    margin-left: 300px;
    min-height: 100vh;
    flex-grow: 1;
    background: #eee;
}
.layout__content-main{
    position: relative;
    background: #eee;
}

.nav-bar-content{
    width: 70%;
}