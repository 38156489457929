.sidebar{
   
    min-width: 300px;
   // width: 50%;
   // height: 100vh;
    position: fixed;
    z-index: 1;
    // left: 0;
    // top: 0;
    // width: 100%;
    background: white;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
}
.sidebar-top{
    color: #1890ff;
    font-size: 30px;
    font-weight: 600;
    padding: 1.5rem 0;

    display: flex;
    // justify-content: left;
    align-items: center;
    color: #000B49;
    h4{
        margin-left: 30px;
        font-size: 40px;
    }
    /* background: #000B49; */
    // background: #000B49;
}
.sidebar-top img{
    width: 200px;
}
.sidebar-list{
    padding: 0 1rem;
}
.sidebar-list-item{
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 1rem;

    border-radius: 18px;
    color: #6b6b6b;
    cursor: pointer;
}
.sidebar-list-item.active{
    background: #1890ff;
    color: white;
}
.sidebar-list-item p{
   margin:0 0 0 1rem;
}

.sidebar-list-item:hover{
    background: #1890ff;
    color: white;
    transition: .3s;
}

.admin-order-new{
    position: absolute;
    top: 0px;
    right: -10px;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;

    background: #1890ff;
    color: white;
    font-size: 13px;
}

.sidebar-list-item{
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 1rem;

    border-radius: 18px;
    color: #6b6b6b;
}
.sidebar-list-item.active{
    background: #1890ff;
    color: white;
}
.sidebar-list-item p{
   margin:0 0 0 1rem;
}

.sidebar-list-item:hover{
    background: rgba(#1890ff, 0.5);
    color: white;
    transition: .3s;
}

.btn-blue{
    background: #007aff;
    color: white;
}

.btn-white{
    background: white;
    color: #6b6b6b;
}
