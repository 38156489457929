@import '../variables.scss';

.main-sub{
    .top-main-sub{
        height: auto;
        background: white;
        padding-bottom: 10px;
    }

    .main-content-sub{
        .container{
            padding-bottom: 40px;
            // padding-top: 40px;
            .list-product{
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                .item-product{
                    background: white;
                    width: 260px;
                    height: 450px;
                    border: 1px solid rgba($color: #e0e0e0, $alpha:0.5);
                    border-radius: 0;
                    overflow: hidden;
                    &:hover{
                        z-index: 3;
                        box-shadow: 0px 2px 10px rgba($color: #333, $alpha: 0.4);
                    }
                    .image{
                        overflow: hidden;
                        width: 200px;
                        // height: 150px;
                        img{
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                        }
                    }
                    
                }
            }

            button{
                margin-top: 50px;
                // padding: 10px 30px;
                padding: 15px 50px;
                background-color: $btn-color;
                color: white;
                position: relative;
                transform: translateX(-50%);
                left: 50%;         
                border-radius: 5px;
                cursor: pointer;      
                font-size: 18px; 
                transition: all 0.4s;
                &:hover{
                    background-color: #3271ac;
                }
            }
        }
    }   

}