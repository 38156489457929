

.admin-cate .add-cate{
    display: flex;
}
.admin-cate .add-cate span{
    font-size: 35px;
    transition: all 0.3s;
}
.admin-cate .add-cate span:hover{
    color: #1890ff;
}
.admin-cate{
    width: 80%;
    // margin-left: 100px;
    // margin-top: 20px;
}
.admin-cate-link{
    display: flex;
    justify-content: end;
    margin-right: 100px;

    // margin-right: 6.5%;
    button{
        display: flex;
        align-items: center;
        font-size: 20px;
        background: #379237;
        padding: 10px 10px;
        color: white;
        font-weight: 400;
        margin: 20px 0;
        border-radius: 6px;
    }

}
.admin-cate span{
    display: flex;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #6b6b6b;
}
.admin-cate-list{
    background: white;
    margin: 0 .5rem 0 1rem;
    color: #6b6b6b;
    padding: 1rem;
    width: 90%;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .image{
        width: 70px;
        height: 100px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.admin-cate-list table{
    width: 80%;
    text-align: center;
    padding: 1rem;
    border-collapse: collapse;
}

.admin-cate-list table th{
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 600;
    background: #eff2f7;
    padding: .5rem 0;
   // width: 10%;
}
.admin-cate-list table td{
    padding: .5rem 0;
    font-size: 16px;
}


.admin-cate-list tr .delete-cate, .admin-cate-list tr .update-cate, .review-cate{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    text-align: center;
}
.admin-cate-list tr .delete-cate span, .admin-cate-list tr .update-cate span{
    border-radius: 50%;
}
.admin-cate-list tr .delete-cate:hover span{
    background: rgb(223, 28, 28);
    transition: .3s;
}
.admin-cate-list tr .update-cate:hover span{
    background: #1890ff;
    transition: .3s;
}
.admin-cate-list tr .delete-cate:hover span, .admin-cate-list tr .update-cate:hover span{
    color: white;
}

.admin-cate-list .pagination{
    padding: 1rem 0;
    display: flex;
}
.admin-cate-list .pagination span{
    display: block;
    padding: 0;
    font-size: 14px;
    font-weight: 0;
}


/* ----- create cate */
/* .admin-create{

} */
.admin-create{
    width: 80%;
    margin-left: 200px;
    .admin-create-cate{
        display: flex;
        flex-wrap: wrap;
        span{
            display: block;
        }
        input{
            width: 50%;
        }
        .cate{
            position: relative;
            width: 40%;
            .cate-select{
                option{
                    padding: 10px;
                    cursor: pointer;
                }
            }
            
        }
    }
}
.admin-create > span{
    display: flex;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #6b6b6b;
}
.admin-create-cate{
    display: flex;
    flex-direction: column;
    background: white;
    margin: 0rem .5rem 1rem 1rem;
    padding: 1rem;
    width: 90%;

    span{
        margin-bottom: 10px;

    }
}
.admin-create-cate input, select, textarea{
    margin-bottom: 1rem;
    padding: 3px;
    font-size: 14px;
    // width: 80%;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.select-type{
    margin-bottom: 1rem;
}
.admin-create-cate button{
    background: white;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    border: 1px solid #333;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    width: 80%;
    transition: all .3s;
 
}
.admin-create-cate button:hover{
    background: #1890ff;
    color: white;
    border: 1px solid transparent;
    transition: .3s;
}
.admin-create-cate .filter-menu-firm{
    margin-top: 0;
    margin-bottom: 1rem;
}

.list-box{
    button{
        border: 1px solid rgba(#4D46FA,0.3);
        padding: 10px 16px;
        border-radius: 32px;
        margin: 10px 15px;
        color: #000;
        font-weight: bold;
        background: white;
        transition: all .3s;
        &:hover{
            background: #4D46FA;
            color: white;
        }
    }
}

.box-brand{
    background: white;
    
    h4{
        font-size: 22px;
        text-align: center;
        padding: 20px 0;
    }

    form{

        // margin-left:20px;

    }
    
    .input-group{
        display: flex;
        margin-bottom: 20px;
        span{
            font-size: 16px;
            // padding: 5px;
        }
        input{
            background: white;
            border: 1px solid #e4e4e4;
            padding: 5px 16px;
            border-radius: 4px;
        }
        }

    .add-brand{
        // text-align: center;
        button{
            margin-top: 20px;
            background: green;
            color: white;
            // font-weight: bold;
            padding: 7px 30px;
            border-radius: 7px;
            font-size: 18px;
            text-align: center;
            cursor: pointer;
            opacity: 0.5;
            transition: all .3s;
            &:hover{
                // background: rgba(green, 0.9);
                opacity: 1;
            }
        }
    }
}

.list-brand{
    margin: 20px auto auto 20px;
    p{
        font-weight: bold;
        font-size: 17px;
    }
}

.box-brand{
    margin-left: 1rem;
    width: 80%;
    border-radius: 12px;
}
// .upload-icon{
//     // position: relative;
//     &::before{
//         content: '';
//         background: url('../../../../assets/images/preImage.png');
//         position: absolute;

//     }
// }

.image-upload > input
{
    display: none;
}
.upload-icon{
	width: 100px;
	height: 97px;
	border: 2px solid #ced4da;
	border-style: dotted;
	border-radius: 18px;
    img{
        width: 100%;
        
        
    }
}
.upload-icon img{
	width: 60px;
	height: 60px;
	margin:19px;
	cursor: pointer;
}

.img-group{
    form{
        margin-right: 20px;
    }
}

.delete-brand{
    width: 50px;
    
}

.update-brand{
    width: 50px;
}



.box-specifications{
    background: white;
    border-radius: 12px;
    width: 80%;
    margin-left: 1rem;
    padding: 30px;
    span{
        
    }
    select, input{
        background: #fff;
        padding: 7px 16px;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        width: 500px;

    }
    .list-specifications{
  
        width: 500px;
        .specification-selected{
           
            .tags-input-container{
                border: 1px solid #e4e4e4;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
                padding: 5px 10px;
                padding: .5em;
                padding-bottom: 1rem;
                border-radius: 3px;
                width: min(80vw, 600px);
                margin-top: 1em;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: .5em;
                .input-specification{
                    border: 1px solid #e4e4e4;
                    padding: 5px 10px;
                    border-radius: 4px;
                    margin: 10px 0;
                    input{
                    }
                }
            }
            
            .tag-item{
                background-color: #e4e4e4;
                display: inline-block;
                padding: 5px;
                border-radius: 20px;
            }
            .tag-item .close{
                height: 20px;
                width: 20px;
                background-color: rgb(48, 48, 48);
                color: #fff;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-left: .5em;
                font-size: 18px;
                cursor: pointer;
            }
            
            .tags-input{
                flex-grow: 1;
                padding: .5em 0;
                border: none;
                outline: none;
            }
            .delete-specification{
                color: gray;
                cursor: pointer;
                font-weight: bold;
                transition: all .3s;
                &:hover{
                    color: red;
                }
            }
        }

        .submit{
            margin-top: 10px;
            button{
                background: green;
                opacity: 0.5;
                color: white;
                padding: 10px 15px;
                border-radius: 7px;
                transition: all .3s;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}