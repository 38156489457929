
.admin-event{
    background: whitesmoke;
}
.banner-home{
    // margin-left: 20px;

    h3{
        font-size: 32px;
        text-align: center;
    }

    .main-banner{
        text-align: center;
        img{
            width: 1150px;
            height: 200px;
            margin-bottom: 20px;
        }
    }
    .extra-banner{
        
        img{
            width: 1150px;
            height: 100px;
            margin-bottom: 20px;
        }
    }
    p{
        text-align: left;
        margin: 15px 0 15px 115px;
        font-weight: bold;
    }
    
}


.banner-slide{

    // position: absolute; 
    // transform: translateX(31%);
    // right: 50%;
    // left: 0;
    // margin: 40px auto;
   height: 80%;

    .slick-slider{
        width: 1000px;
        margin-left: 150px;
        img{
            border-radius: 10px;
            width: 98%;
            margin-left: 10px;
        }
    

    .slick-arrow{
        background: white !important;

        i{
            color: #666 !important;
            transition: all .4s;
        }
        &:hover{
            i{
                color: #333 !important;
            }
        }
        // &::before{
            
        //     color: white;
        //     font-size: 50px;
        //     position: absolute;
        //     transform: translate(50%, 50%);
        //     right: 50%;
        //     bottom: 50%;
            
        // }

        &.slick-next{
            position: absolute;
            box-shadow: -6px 0 4px rgba(#333,0.3);
            top: 45%;
            transition: all 0.4s;
            // &:hover{
            //    background-color: #e0e0e0 !important;
            // }
            &::before{
               font-size: 0 !important;
               
            }
          
        }
        &.slick-prev{
            box-shadow: 6px 0 4px rgba(#333,0.3);
            transition: all 0.4s;
            top: 45%;
            left: -12px;
            // &:hover{
            //     background-color: #e0e0e0 !important;
            // }
            &::before{
               font-size: 0 !important;
            }
        }
        
    }

}

}



.edit{
    display: flex;
    background: #2be;
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-radius: 5px;
    margin: -5px auto;
    button{
        margin-right: 5px;
        font-size: 17px;
        font-weight: bold;
        

    }
}
.upload-image-event{
    margin-bottom: 15px;

    .image-upload > input
    {
        display: none;
    }
    .upload-icon{
        // width: 850px;
        // height: 100px;
        border: 2px solid #ced4da;
        border-style: dotted;
        border-radius: 18px;
        img{
            width: 92%;
            
           
        }
    }
    .upload-icon img{
        // width: 800px;
        // margin:19px;
        cursor: pointer;
    }

    .img-group{
        form{
            margin-right: 20px;
        }
    }

}

.modal-banner-home{
    width: 1300px;
    

   
}

.save-image{
    text-align: center;
    
    button{
        background: #2be;
        width: 150px;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-radius: 5px;
        margin: 10px auto;
        font-size: 17px;
        font-weight: bold;
    }
}

.banner-orther{
    p{
        font-weight: bold;
    }
}