@import '../variables.scss';



.bg-admin{
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;

    .cart{
        img{
            display: none;
        }
    }
    // .header{
    //     display: none;
    // }
    .header-content{
        display: none;
    }

    .admin{
    
        .admin-nav-bar{
          
            height:100vh;
            display: flex;
            .nav-bar-content{
                width: 100%;

                .dashboard-top-content{
                    .dashboard-top-content-avatar{
                        cursor: pointer;
                        margin-right: 8%;
                        margin-top: 20px;
                        span{
                            font-size: 18px;
                        }
                        img{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    .sidebar{
        height: 100%;
        width: 13%;
        position: relative;
        .list-menu{
          //  width: 20%;
            background: $primary-color;
            
            ul{
                li{
                    color: white;
                    font-size: 20px;
                    padding: 10px 10px;
                    cursor: pointer;
                    transition: all .5s;
                    span{
                        margin-left: 10px;
                    }
                    &:hover{
                        background: rgba(#fff,0.3);
                    }
                }
            }
        }
        
    }

}