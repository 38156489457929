@import '../variables.scss';

.smart-phone{

   
     .main-sub{
        .main-content-sub{
            .container{
                .list-product{
                    .item-product{
                        // height: 400px;
                        .image{
                            padding-top: 10px;
                            height: 150px;
                            img{

                            }
                        }
                    }
                }
            }
        }
     }
    

}