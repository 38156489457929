@import '../variables.scss';

.accessories{
  position: relative;
    .main-accessories{
      
        .bg-slider{
            height: auto;
            padding-bottom: 20px;
            background: white;
        }

        .container{
            padding-top: 30px;
            height: auto;
            .bg-cate-accessory{
              border-radius: 10px;
              position: fixed;
              top: 161px;
              height: 130px;
              width: 1300px;
              z-index: 9;
              background: #FEFAE0;
              .wrapper {
                cursor: pointer;
                // padding: 10px;
              }

              // height: 100%;

              // .wrapper{
              //   background: #000;
              // }
            }
            .feature-list-cate {
                h2 {
                }

                
                .wrapper-group-cate {
                  // top: 140px;
                  
                  // z-index: 10;
                  margin-top: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  align-items: center;
                  .wrapper {
                    padding: 10px;
                    text-align: center;
                    width: 9%;
                    &:hover{
                        .content{
                            color: $btn-color;
                        }
                    }
                    
                    .img-wrapper{
                      
                    }
                      img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        // background: blue;
                        // margin-left: 15px;
          
                        &.backupCharger {
                          background: linear-gradient(90deg, #6ac1ff 0%, #8bfffe 100%);
                        }
                        &.chargeCable {
                          background: linear-gradient(90deg, #ff8ca1 0%, #ffd0d0 100%);
                        }
                        &.headphone {
                          background: linear-gradient(90deg, #f8d383 0%, #ffbf83 100%);
                        }
                        &.speaker {
                          background: linear-gradient(90deg, #ede574 0%, #e1f5c4 100%);
                        }
                        &.tech {
                          background: linear-gradient(
                            135deg,
                            #f5ee27 14.65%,
                            #f4b43f 85.36%
                          );
                        }
                        &.camera {
                          background: linear-gradient(
                            135deg,
                            #f5ee27 14.65%,
                            #8bc34a 85.36%
                          );
                        }
                        &.wifi {
                          background: linear-gradient(
                            135deg,
                            #ffd740 14.65%,
                            #e040bd 85.36%
                          );
                        }
                        &.bumper {
                          background: linear-gradient(90deg, #9ce8fb 0%, #b4ffe4 100%);
                        }
                        &.mouse {
                          background: linear-gradient(
                            143.24deg,
                            #ffd772 14.65%,
                            #f3a618 71.95%
                          );
                        }
                        &.keyBoard {
                          background: linear-gradient(
                            135deg,
                            #377ef4 14.65%,
                            #cd48e3 85.36%
                          );
                        }
                      }
                      .content {
                        // text-align: start;
                        // flex-wrap: wrap;
                        // width: 100px;
                        font-size: 15px;
                        // line-clamp: 2;
                        text-align: center;
                        width: 100%;
                        // width: 100px;
                        height: 20px;

                        // text-align: center;
                        // position: absolute;
                        // transform: translateY(10px);
          
                        // padding-right: 10px;
                      }
                    
                  }
                }
              }

              .list-brand {
                margin-top: 70px;
                width: 1320px;
                // z-index: -1;
                .listBrand {
                  margin-top: 10px;
                  display: flex;
                  flex-wrap: wrap;
                  // justify-content: space-between;
                  .item {
                    width: 110px;
                    margin: 0 10px;
                    height: 40px;
                    border: solid 1px #e0e0e0;
                    border-radius: 5px;
                    position: relative;
                    background: white;
                    margin-top: 10px;
                    &:nth-child(1),&:nth-child(11)
                    {
                      margin-left: 0;
                    }
                    img {
                      width: 80%;
                      // height: 100%;
                      position: absolute;
                      transform: translate(50%, 50%);
                      bottom: 50%;
                      right: 50%;
                    }
                  }
          
                  .btnViewMore {
                    margin-top: 10px;
                    background: white;
                    width: 110px;
                    height: 40px;
                    border: solid 1px rgba($color: #e0e0e0, $alpha: 1);
                    border-radius: 5px;
                    color: $btn-color;
                    cursor: pointer;
                  }
                }
              }

            
        

        }

    }
}