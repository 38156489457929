.update-filter .filter-menu{
    background: white;
    padding: .5rem 1rem;
    margin-left: 1rem;
}
.update-filter .filter-menu-item-name:hover{
    border: 1px solid #1890ff;
}
.update-filter .filter-menu-item-name:hover span{
    color: #1890ff;
}






.update-filter-title{
    display: flex;
    padding: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #6b6b6b;
}
.update-filter-info{
    background: #fff;
    margin: 0rem .5rem 1rem 1rem;
    padding: 1rem;
}
.update-filter-info > span{
    display: flex;
    margin-bottom: 1rem;
    font-size: 17px;
    font-weight: 600;
    color: #6b6b6b;
}
.update-filter-info form{
    display: flex;
    flex-direction: column;
}
.update-filter-info form button{
    display: flex;
    justify-content: center;
    width: 200px;
    border-radius: 20px;

    padding: .5rem 1rem;
    border: none;
    outline: none;
    color: white;
    background: #1890ff;
}
.update-filter-info form input{
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;
    width: 100%;
}
.update-filter-info form > span{
    border: 1px solid gray;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;
    width: 100%;

    cursor: pointer;
}
.option-list-item{
    position: relative;
}
.option-list-item span{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-95%);
    font-size: 15px;

    cursor: pointer;
} 


/* --------- create type product */
.create-type{
    background: #fff;
    margin: 0rem .5rem 1rem 1rem;
    padding: 1rem;
}
.create-type > span{
    display: flex;
    margin-bottom: 1rem;
    font-size: 17px;
    font-weight: 600;
    color: #6b6b6b;
}
.create-type form{
    display: flex;
    flex-direction: column;
}
.create-type form button{
    display: flex;
    justify-content: center;
    width: 200px;
    border-radius: 20px;

    padding: .5rem 1rem;
    border: none;
    outline: none;
    color: white;
    background: #1890ff;
}
.create-type form input{
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;
    width: 100%;
}
.filter-menu-firm-item-delete {
    position: absolute;
    top: 0;
    right: -10px;
    width: 20px;
    height: 20px;
    color: white;
    background: #1890ff;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
}

.update-filter .filter-menu-firm {
    background: white;
    margin: 0 .5rem 0 1rem;
    padding: 1rem 1rem 0 1rem;
}





/* -------------- update select  */
.form-update-select{
    display: flex;
    flex-direction: column;
}
.form-update-select button{
    display: flex;
    justify-content: center;
    width: 200px;
    border-radius: 20px;

    padding: .5rem 1rem;
    border: none;
    outline: none;
    color: white;
    background: #1890ff;
}
.form-update-select input{
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;
    width: 100%;
}
.form-update-select > span{
    border: 1px solid gray;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    font-size: 14px;
    width: 100%;

    cursor: pointer;
}
.form-update-select .option-list-item{
    position: relative;
}
.form-update-select .option-list-item span{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-95%);
    font-size: 15px;

    cursor: pointer;
}

