@import'../variables.scss';
.container{
    &.container-cart{
        width: 800px;
        .title{
            padding-top: 30px;
            display: flex;
            justify-content: space-between;
            .buy-another{
                color: $btn-color;
                padding-left: 10px;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border: 2px solid $btn-color;
                    left: 0;
                    border-right: transparent;
                    border-top: transparent;
                    // bottom: 50%;
                    transform: rotate(45deg) translateY(50%);
                }
            }
        }
        .info-cart{
            margin-top: 30px;
            height: auto;
            background-color: white;
            padding: 0px 30px;
            border-radius: 5px;
            margin-bottom: 20px;
            padding-bottom: 30px;
            .listing-cart{
                
                .item{
                    height: auto;
                    display: flex;
                    position: relative;
                    padding-top: 30px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 108%;
                        border: 1px solid rgba($color: #e4e4e4, $alpha: 0.5);
                        left: -30px;
                        bottom: -15px;
                    }
                  
                    .img-sp{
                        width: 15%;
                        img{
                            width: 100%;
                        }
                        .delete{
                            padding-top: 10px;
                            text-align: center;
                            &:hover{
                                i,button{
                                    color: $red;
                                }
                            }
                        }
                    }
                    .content-product{
                        width: 85%;
                        padding-left: 10px;
                        .name-and-price{
                            display: flex;
                            justify-content: space-between;
                            .name{
                                width: 400px;
                                line-height: 1.5;
                                font-weight: bold;
                            }
                            .price{
                                color: $red;
                                text-align: right;
                                font-size: 16px;
                            }
                            .price-old{
                                font-size: 16px;
                                text-align: right;
                                padding-top: 5px;
                                text-decoration: line-through;
                            }
                        }
                        .promotion{
                            color: $blue;
                            padding-top: 10px;
                        }
                        .product-quantity-and-color{
                            padding-top: 10px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .color-product{
                                
                            }
                            .select-quantity{
                                .decrease{
                                    border-top-left-radius: 3px;
                                    border-bottom-left-radius: 3px;
                                }
                                .increase{
                                    border-top-right-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                }
                                button{
                                    border: 1px solid black;
                                    padding: 10px;
                                }
                                input{
                                    border: 1px solid black;
                                    padding: 10px;
                                    width:50px;
                                    text-align: center;
                                    border-color: black transparent;
                                }
                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                    }
                            }
                        }

                    }
                }

                .total-price{
                    padding-top: 30px;
                    position: relative;
                    background-color: white;
                    width: 108%;
                    padding-left: 30px;
                    padding-right: 30px;
                    left: -30px;
                    display: flex;
                    justify-content: space-between;
                
                }
            }

            .info-customer{
                padding-top: 40px;
                position: relative;
                &::before{
                    content: '';
                    width: 108%;
                    position: absolute;
                    border: 1px solid rgba($color: #e4e4e4, $alpha: 0.5);
                    left: -30px;
                    top: 20px;
                }

                .name-and-number{
                    padding-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    .name{
                        width: 47%;
                        position: relative;
                    }
                    .number{
                        width: 47%;
                        position: relative;
                    }
                    .input{
                        transition: all 0.5s;
                        &:hover{
                            .style-change{
                                background-color: white;
                                transform: translate(15px,-15px);
                                font-size: 14px;
                                padding: 0 3px;
                            }
                            input{
                                box-shadow: 0px 0px 5px 0px #FF884B;
                                border-color: #FF884B;
                            }
                        }
                    }
                    input{
                        border: 1px solid rgba($color: #000000, $alpha: 0.3);
                        padding: 15px;
                        // margin-right: 30px;
                        border-radius: 3px;
                        width: 100%;
                        
                    }
                    .style-change{
                        position: absolute;
                        left: 0;
                        bottom: 50%;
                        transition: all 0.2s;
                        transform: translate(15px,50%);
                        // top: 0;
                    }
                }

                .address{
                    // display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 35px;
                        input{
                            margin-top: 10px;
                            padding:  15px;
                            border: 1px solid rgba($color: #000000, $alpha: 0.3);
                            border-radius: 5px;
                            width: 100%;
                        }
                    
                        .change{
                            color: orangered;
                            font-size: 15px;
                            cursor: pointer;
                        }

                }

                .note{
                    margin-top: 30px;
                    textarea{
                        margin-top: 20px;
                        border: 1px solid rgba($color: #000000, $alpha: 0.3);
                        padding: 15px;
                        width: 100%;
                        border-radius: 5px;
                       
                    }
                }

              

            }

            .voucher{
                padding-top: 40px;
                position: relative;
                &::before{
                    content: '';
                    width: 108%;
                    position: absolute;
                    border: 1px solid rgba($color: #e4e4e4, $alpha: 0.5);
                    top: 20px;
                    left: -30px;
                }
                &::after{
                    content: '';
                    width: 108%;
                    position: absolute;
                    border: 1px solid rgba($color: #e4e4e4, $alpha: 0.5);
                    left: -30px;
                    bottom: -20px;
                }
                .input{
                    position: relative;
                    width: 33%;
                    &::before{
                        position: absolute;
                        content: '';
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        
                        border-top: 7px solid black;
                        right: 0;
                        transform: translate(-15px,50%);
                        bottom: 50%;
                        
                    }
                    input{
                    border: 1px solid rgba($color: #000000, $alpha: 0.3);
                    width: 100%;
                    padding: 15px 40px;
                    border-radius: 5px;
                    font-size: 17px;
                    color: black;
                  
                }
                }
                .ic{
                    position: absolute;
                    transform: translate(15px,18px);
                    
                }
            }

            .final-total{
                    padding-top: 50px;
                    
                    .total{
                        display: flex;
                        justify-content: space-between;
                        .tt-price{
                            font-weight: bold;
                        }
                        .price{
                            color: $red;
                            font-weight: bold;
                        }
                    }
                    button{
                        margin-top: 30px;
                        padding: 20px;
                        width: 100%;
                        border-radius: 8px;
                        background-color: $btn-color;
                        color: white;
                        font-size: 20px;
                        font-weight: bold;
                    }
            }
            .drop-down-list{
                position: absolute;
                z-index: 20;
                width: 370px;
                height: 250px;
                border: 1px solid rgba($color: #000000, $alpha: 0.3);
                padding: 20px;
                border-radius: 5px;
                background-color: white;
                transform: translateY(92px);
                &.city{
                    transform:translate(390px,92px)
                }
                &.ward{
                    transform: translate(0,175px);
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 12px;
                    height: 12px;
                    border: 1px solid rgba($color: #000000, $alpha: 0.3);
                    border-bottom: transparent;
                    border-right: transparent;
                    background-color: white;
                    transform: translate(0,-27px) rotate(45deg);
                }
                .input-search{
                    input{
                        padding: 10px;
                        width: 100%;
                        border: 1px solid rgba($color: #000000, $alpha: 0.3);
                        border-radius: 3px;
                    }
                    .ic{
                        position: absolute;
                        transform: translate(-30px,10px);
                        opacity: 0.4;
                    }
                }
                ul{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    overflow-y: scroll;
                    height: 180px;
                    width: 340px;
                    padding-top: 10px;
                    li{
                        width: 50%;
                        height: 50px;
                        cursor: pointer;
                        padding: 10px;
                        color: $blue;
                        &:hover{
                            background-color: $blue;
                            color: white;
                        }
                    }
                
                }
            }

        }
    }

    
}


.err-msg{
    // height: 10px;
    display: block;
    padding-left: 0px ;
    margin-top: 5px;
    color: #F97C7C;
    font-size: 17px;
    position: absolute;
}

.list-address{
    h4{
        font-size: 20px;
        margin: 10px auto;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 10px;
    }
    .address-item{
        // align-items: flex-start;
        .detail{
            width: 80%;
            margin: auto 20px;
            line-height: 1.5;
            .default{
                margin-top: 5px;
                span{
                    color: orangered;
                    border: 0.5px solid orangered;
                    padding: 2px 4px;
                    font-size: 14px;
                }
            }
        }
        .update-address{
            color: #08f;
           span{
                &:hover{
                    text-decoration: underline;
            }
           }
            
        }
        .gray{
            color: gray;
        }
    }
}

.add-address{
    margin-top: 20px;
    button{
        color: gray;
        font-size: 16px;
        border: 1px solid #e4e4e4;
        padding: 5px 10px;
        // background: $primary-color;
    }
}
.submit-address{
    button{
        background: $primary-color;
        color: white;
        transition: all .3s;
        &:hover{
            box-shadow: 0 0 5px 0 $primary-color;
        }


    }
}

.form-address{
    form{
        padding: 20px;

        input{
            border: 1px solid #e4e4e4;
            padding: 10px 15px;
            border-radius: 5px;
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
        }
    }
    button{
        width: 200px;
        color: white;
        padding: 10px 0;
        background: $primary-color;
        // margin: 10px auto;
        border-radius: 5px;
        margin-top: 20px;
        transition: all .3s;
        &:hover{
            box-shadow: 0 0 5px 0 $primary-color;
        }
    }
}


.cart-voucher{
   
    .use-voucher{
        cursor: pointer;
        width: 200px;
        margin-top: 20px;
        padding: 7px 10px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #e4e4e4;
        span{
            margin-left: 10px;
        }
    }
    .input-voucher{
        position: relative;
        .input-group-voucher{
            width: 600px;
            margin-top: 10px;
            padding: 10px;
            border-radius: 4px;
            background: #f1f1f1;
            display: flex;
            input{
                background: white;
                border: 1px solid #e4e4e4;
                padding: 10px 15px;
                border-radius: 4px;
                font-size: 16px;
                color: black;
                flex-grow: 2;
                transition: all .2s;
                &:hover{
 
                    box-shadow: 0px 0px 5px 0px #FF884B;
                    border-color: #FF884B;
                    
                }
                
            }
            button{
                flex-grow: 1;
                background: #ccc;
                border-radius: 4px;
                margin-left: 15px;
                color: white;
            }
        }
    }
    
}

.modal-list-voucher{
    position: absolute;
    width: 420px;
    border: 1px solid #e4e4e4;
    // border-radius: 4px;
    z-index: 10;
    background: white;
    // padding: 10px;
    cursor: pointer;
    .bg-voucher-item{
        transition: all .3s;
        padding: 10px;
        &:hover{
            background: #DEECFC;
        }
        &:not(:last-child){
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 10px;

            

        }
    }
    .voucher-item{
        
        

    }
}




